.applications {
    background-color: #010323;
    color: #ffffff;
    padding: 100px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  
.applications h2 {
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}

.applications h4 {
    line-height: 1;
    padding-bottom: 20px;
    font-size: clamp(1rem, 1rem + 2vw, 1.3rem);
}

.applications_all {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto new lines */
    justify-content: center;
    align-items: center;
    max-width: 1300px; /* Limit maximum width of all applications */
    margin: 0 auto; /* Center the applications within the container */
}

.text_aplication {
    text-align: center;
}

.application1,
.application2 {
    margin: 20px 15px; /* Adjust margin for smaller spacing */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: auto;
}

.picture_application {
    margin: 15px 15px;
    width: 100%;
}

@media screen and (max-width: 1330px) {
    .application2 {
        display: none;
    }
    .application1 {
        max-width: 200px;
    }
}