.benefits{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #dfdfdf;
    padding: 100px 20px;
}
.benefits h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}

.pics_texts_benefits{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pic_text_benefits{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 450px;
    min-height: 550px;
    margin: 30px 0px;
}

.picture_benefits{
    display: flex;
    position: relative;
    width: 250px;
    height: auto;
}

.text_benefits{
    text-align: left;
    padding: 20px;
}
.text_benefits h3{
    line-height: 1;
    padding-bottom: 20px;
    font-size: clamp(1.5rem, 1.5rem + 2vw, 1.8rem);
}
.text_benefits p{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}

@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .pics_texts_benefits{
        flex-direction: column;
    }
    .pic_text_benefits{
        padding: 20px;
        width: 90%;
        max-width: 500px;
    }
    .picture_benefits{
        width: 100%;
        height: auto;
        min-width: 200px;
        margin: 50px;
    }
}