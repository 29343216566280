.hero{
    background-color: #010323;
    height: 740px;
}
.hero_small{
    background-color: #010323;
    height: 450px;
}

.hero_text_pic{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
    padding-bottom: 0px;
}

.hero_text{
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    text-align: left;
    padding-right: 70px;
    width: 640px;
}
.hero_text h1{
    display: flex;
    font-size: clamp(3rem, 3rem + 2vw, 5.5rem);
    line-height: 1;
    padding-bottom: 20px;
}
.hero_text p{
    display: flex;
    font-size: 1.2rem;
    padding-top: 10px;
    line-height: 1.5;
    padding-bottom: 20px;
}
.hero_text a{
    display: flex;
    font-size: 2.5rem;
}
.hero_text a:hover{
    background: #090294;
    transition: 0.3s ease-in-out;
}
.hero_text .show {
    text-decoration: none;
    justify-content: center;
    align-items: center;
    background: #0401D4;
    border-radius: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    width: 260px;
    height: 80px;
}
.hero_text .hide {
    display: none;
}

.hero_pic_big{
    display: flex;
    position: relative;
    margin-top: 40px;
    height: 650px;
    width: 650px;
    min-width: 550px;
}

.hero_pic_small{
    display: flex;
    position: relative;
    padding: 80px 100px;
    width: 650px;
    min-width: 550px;
    height: 500px;
}

@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .hero{
        height: 130%;
    }
    .hero_small{
        height: 100%;
    }
    .hero_text_pic {
        padding: 2%;
        flex-direction: column; /* Stack divs vertically */
        align-items: center; /* Center items horizontally */
    }
    .hero_text{
        max-width: 650px;
        padding: 2%;
        padding-top: 120px;
        padding-bottom: 5%;
        width: 95%;
    }
    .hero_text h1{
        font-size: clamp(3rem, 2.5rem + 2vw, 5.5rem);
    }
    .hero_text p{
        font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
    }
    .hero_pic_big{
        max-width: 600px;
        min-width: 200px;
        margin: 0%;
        width: 95%;
        height: auto;
    }
    .hero_pic_small {
        max-width: 400px;
        min-width: 200px;
        margin: 0px;
        padding: 0px;
        width: 70%;
        height: auto;
    }
    .hero_text .show {
        border-radius: 15px;
        font-size: 1.2rem;
        width: 220px;
        height: 60px;
    }
}