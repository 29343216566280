.workflow{
    background-color: #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
}
.workflow h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}

.pic_text_workflow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px 0px;
    max-width: 1250px;
}

.text_workflow{
    max-width: 600px;
    text-align: left;
    padding: 0px 50px;
}
.text_workflow h3{
    line-height: 1;
    padding-bottom: 20px;
    font-size: clamp(1.5rem, 1.5rem + 2vw, 1.8rem);
}
.text_workflow p{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}

.pic_workflow{
    display: flex;
    position: relative;
    align-items: center;
    width: 650px;
    justify-content: space-between;
}
.pic_workflow img{
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 30px;
    box-shadow: -1px 1px 60px -10px #010323;
}


@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .workflow{
        padding-bottom: 20px;
    }
    .pic_text_workflow{
        flex-direction: column;
        width: 90%;
    }
    .text_workflow{
        padding: 20px 0px;
    }
    .pic_workflow{
        width: 100%;
        max-width: 600px;
    }
}