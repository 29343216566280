.blogs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 20px;
}

.blogs h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}

.blogs_card{
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto new lines */
    justify-content: center;
    align-items: center;
    max-width: 1300px; /* Limit maximum width of all applications */
    margin: 50px auto; /* Center the applications within the container */
}

.blog_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 400px;
    height: 800px;
    margin: 10px;
    text-align: start;
    box-shadow: 0 5px 15px 2px #2a2a2a;
    border-radius: 20px;
    cursor: pointer;
}

.blog_pic{
    width: 380px;
    height: auto;
    overflow: hidden;
}
.blog_pic img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
}
.blog_pic:hover img{
    transform: scale(1.3);
}

.blog_card h3{
    line-height: 1;
    padding: 20px 0px;
    font-size: clamp(1.5rem, 1.5rem + 2vw, 1.8rem);
}

.blog_card p{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}

.blog_url{
    color: #000000;
    text-decoration: none;
}

@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .blogs_card{
        flex-direction: column;
        margin: 30px auto; /* Center the applications within the container */
    }
    .blog_card{
        margin: 20px 0px;
        width: 100%;
        max-width: 700px;
        min-height: 660px;
        height: auto;
    }
    .blog_pic{
        width: 100%;
        height: auto;
    }
    .blog_pic img{
        width: 100%;
        height: auto;
    }
}