.features{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 20px;
}
.features h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}

.feature1,
.feature2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px 0px;
    width: 1250px;
}
.feature2{
    margin-bottom: 0px;
}

.text_features{
    width: 550px;
    text-align: left;
    padding: 0px 50px;
}

.text_features h3{
    line-height: 1;
    padding-bottom: 20px;
    font-size: clamp(1.5rem, 1.5rem + 2vw, 1.8rem);
}

.text_features li{
    line-height: 1.5;
    margin: 10px 0px;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}
.text_features b{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}

.pic_features{
    display: flex;
    position: relative;
    align-items: center;
    width: 700px;
    justify-content: space-between;
    z-index: -99;
}
.pic_features img{
    width: 345px;
    height: 500px;
    object-fit: cover;
    border-radius: 30px;
    box-shadow: -1px 1px 62px -18px #010323;
}
.pic_features img:nth-child(2){
    position: absolute;
    top: 0;
    right: 0;
}


@media screen and (max-width: 1300px){ /*vezano za meni kad je telefon u pitanju*/
    .features{
        margin-bottom: 20px;
    }
    
    .feature1,
    .feature2 {
        flex-direction: column;
        width: 95%;
    }
    .feature1{
        margin-top: 20px;
    }
    .text_features{
        width: 95%;
        padding: 70px 0px;
    }
    .pic_features{
        width: 100%;
    }
    .pic_features img{
        width: 49%;
        height: 100%;
    }
}