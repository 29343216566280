.contact_us{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #dfdfdf;
    padding: 100px 20px;
}
.contact_us h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}
.contact_us p{
    margin-top: 50px;
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}
.contact_us b{
    margin-top: 50px;
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}
.contact_us_text{
    max-width: 900px;
    text-align: left;
    width: 98%;
}