.partners{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #dfdfdf;
    padding: 100px 20px;
}
.partners h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}

.partners_all{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.partner{
    max-width: 350px;
    width: 30%;
    padding: 20px;
    margin: 32px;
    outline: 3px solid #999999;
    border-radius: 30px;
}

.author_partner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.pic_partner{
    max-width: 120px;
    max-height: 120px;
    padding: 8px;
}

.text_partner{
    padding: 30px 10px;
    text-align: left;
}
.text_partner p{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}


@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .partners_all{
        flex-direction: column;
    }
    .partner{
        max-width: 750px;
        width: 100%;
    }
}