.footer{
    background-color: #010323;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer_info{
    width: 1250px;
    padding: 120px 20px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer_info i{
    color: #444444;
    padding: 10px;
}
.footer_info i:hover{
    color: #999999;
}

.logo_footer{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coloumn_footer{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.coloumn_footer a{
    text-decoration: none;
    color: #fafafa;
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}
.coloumn_footer p{
    color: #fafafa;
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}
.coloumn_footer a:hover{
    text-decoration: underline;
}
.coloumn_footer h4{
    font-size: 1.2rem;
    padding: 16px;
}
.social_footer{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.font_icon{
    height: 40px;
    margin: 10px;
    width: auto;
    color: #444444;
}
.font_icon:hover{
    color: #fafafa;
}


.footer img{
    height: 120px;
}


.footer_copyright{
    padding: 30px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer_copyright p{
    font-size: 0.9rem;
    text-align: center;
}


@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .footer_info{
        flex-direction: column;
        width: 95%;
    }
    .logo_footer{
        margin: 0px;
        margin-bottom: 50px;
        width: 90%;
    }
    .coloumn_footer{
        width: 80%;
        margin: 0px;
        margin-bottom: 50px;
    }
    .social_footer{
        width: 80%;
        margin-top: 30px;
    }
    .footer_copyright{
        flex-direction: column;
    }
}