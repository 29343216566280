.problem_solution{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 100px 20px;
}

.pic_text_problem_solution{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pic_problem_solution{
    display: flex;
    position: relative;
    height: 1200px;
    width: 600px;
}

.texts_problem_solution{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    padding-left: 50px;
    max-width: 600px;
}

.text_problem_solution{
    text-align: left;
    margin: 40px 0px;
    padding: 20px;
    border-radius: 20px;
    border: 3px solid #cfcfcf;
}
.problem_solution h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}
.text_problem_solution h3{
    line-height: 1;
    padding-bottom: 20px;
    font-size: clamp(1.5rem, 1.5rem + 2vw, 1.8rem);
}
.text_problem_solution p{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}

@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .pic_text_problem_solution{
        flex-direction: column; /* Stack divs vertically */
    }
    .pic_problem_solution{
        width: 70%;
        height: auto;
        min-width: 200px;
        margin: 50px;
    }
    .texts_problem_solution{
        max-width: 1000px;
        margin-top: 15px;
        padding: 0px;
        width: 95%;
    }
    .text_problem_solution{
        padding: 10px;
        border-radius: 10px;
    }
}