.navbar{
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    width: 95%;
    max-width: 1300px;
    height: 75px;
    border-radius: 19px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%); /*ovaj i par iznad odgovorno za pozicioniranje nav*/
    z-index: 9999;
    box-shadow: 0 0 15px 3px #010323;
}
.Logo { /* logo velicina */
    height: 70px;
}

.nav_menu{
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}

.nav-links{
    text-decoration: none;
    color: #000;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 15px;
    white-space: nowrap;
    position: relative;
}
.nav-links:hover{
    color: #090294;
}

.nav-links.active {
    color: #090294;
}


.nav-links-contact {
    text-decoration: none;
    color: #fff;
    background-color: #0401D4;
    padding: 15px 20px;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.nav-links-contact i{
    padding-right: 10px;
}

.nav-links-contact:hover{
    background-color: #090294;
    color: #fff;
}

.menu_icons{
    display: none;
}

@media screen and (max-width: 1000px){ /*vezano za meni kad je telefon u pitanju*/
    .nav_menu{
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -150%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: 0.4s ease-in-out;
        border-radius: 20px;
    }
    .nav_menu.active{ /* ova sledeca tri sam dodao da bi bili u centru*/
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }
    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .menu_icons{
        display: block;
    }
    .menu_icons i{
        font-size: 1.2rem;
        color: #222;
    }
}

@media screen and (max-width: 500px){ /*vezano za meni kad je telefon u pitanju*/
    .navbar{
        padding-left: 10px;
    }
    .Logo { /* logo velicina */
        height: 45px;
    }
}