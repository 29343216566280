.mission_vision{
    background-color: #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 100px 20px;
}

.mission_vision_item{
    margin: 100px 50px;
    width: 360px;
    min-height: 450px;
    text-align: left;
}
.mission_vision_item h3{
    line-height: 1;
    padding-bottom: 20px;
    font-size: clamp(1.5rem, 1.5rem + 2vw, 1.8rem);
}
.mission_vision_item p{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}

.picture_mision_vision{
    width: 250px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .mission_vision{
        flex-direction: column;
    }
    .mission_vision_item{
        margin: 100px 0px;
        width: 100%;
        max-width: 700px;
        min-height: auto;
    }
}