.cta{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 20px;
}
.cta h2{
    text-align: center;
    font-size: clamp(2rem, 2rem + 2vw, 2.5rem);
}

.cta_name_email{
    display: flex;
    flex-direction: row;
    align-items: center;

}

.cta_input{
    display: flex;
    flex-direction: column;
    width: 500px;
}

.cta form{
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
}

.cta label{
    width: 500px;
    text-align: left;
    margin-left: 30px;
    font-size: 1.2rem;
}

.cta input{
    margin: 10px 30px;
    margin-bottom: 40px;
    padding: 0 15px;
    border-radius: 15px;
    border: 2px solid #010323;
    height: 50px;
}

.cta textarea{
    margin: 10px 30px;
    margin-bottom: 40px;
    padding: 15px 15px;
    border-radius: 15px;
    border: 2px solid #010323;
    height: 300px;
    min-height: 100px;
    max-height: 500px;
    resize: vertical; /* Allow vertical resizing */
}


.cta button{
    align-items: center;
    margin: 0px 30px;
    margin-bottom: 15px;
    height: 60px;
    width: auto;
    border-radius: 15px;
    background: #010323;
    color: white;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
}


@media screen and (max-width: 1100px){ /*vezano za meni kad je telefon u pitanju*/
    .cta form{
        max-width: 800px;
        width: 100%;
    }
    .cta_name_email{
        flex-direction: column;
    }
    .cta_input{
        width: 100%;
    }
    .cta input{
        margin: 10px 0px;
    }
    
    .cta textarea{
        margin: 10px 0px;
    }
    .cta button{
        margin: 0px 0px;
    }
    .cta label{
        margin-left: 5px;
    }
}