.error{
    display: flex;
    justify-content: center;
    align-items: center;
}
.error_item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 700px;
    max-width: 810px;
    padding: 10px;
}
.error h3{
    line-height: 1.5;
    padding-bottom: 20px;
    font-size: clamp(3rem, 3rem + 4vw, 6rem);
}

.error h4{
    line-height: 1.5;
    padding-bottom: 20px;
    font-size: clamp(1.5rem, 1.5rem + 2vw, 1.8rem);
}
.error p{
    line-height: 1.5;
    font-size: clamp(1rem, 1rem + 2vw, 1.2rem);
}

.back_to_home{
    margin: 40px;
    text-decoration: none;
    color: #fff;
    background-color: #0401D4;
    padding: 20px 30px;
    white-space: nowrap;
    border-radius: 20px;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}